import TwoSideText from '@/components/TwoSideText'
import { RoutePathEnum } from '@/constants/routePath'
import { Button, Divider } from '@mui/material'
import { formattedAmount } from '@pimy-b2cweb/frontend-lib'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Loading from '@/components/Loading'
import { useInitReceiptData } from './hooks/useInitReceiptData'
import { DD_MMM_YYYY, HH_MM_SS_A, NO_REFERRER } from '@/constants'
import { OrderStatusEnum } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { validateExternalURLs } from '@/utils'
import useSwitchLang from '@/hooks/useSwitchLang'
import { useEffect } from 'react'

dayjs.extend(utc)

const Receipt = () => {
  const { t } = useTranslation(['cashInPage', 'common'])
  const navigate = useNavigate()
  const { trxRefNo } = useParams<{ trxRefNo: string }>()
  const decodedTrxRefNo = atob(trxRefNo || '')

  const { receiptData, isLoading, isError } = useInitReceiptData(
    decodedTrxRefNo || ''
  )
  const date = dayjs.utc(receiptData?.createdDate).local()

  useEffect(() => {
    if (!!isError) {
      navigate(RoutePathEnum.ERROR)
    }
  }, [isError])
  const { langCode } = useSwitchLang()

  const goExternalEpf = () => {
    window.open(
      validateExternalURLs(
        process.env.REACT_APP_EPF_PORTAL?.replace('[LANG]', langCode) as string
      ),
      '_self',
      NO_REFERRER
    )
  }

  return (
    <>
      {isLoading ? (
        <Loading isLoadingPage={true} />
      ) : (
        <>
          {receiptData && (
            <div className='flex justify-center items-center min-h-screen bg-gray-100'>
              <div className='bg-white shadow-md rounded-lg p-6 w-full max-w-md'>
                {receiptData?.status == OrderStatusEnum.SETTLED ? (
                  <div>
                    <h1 className='mb-4 font-bold' style={{ color: 'green' }}>
                      {t('cash-in-successful')}
                    </h1>
                    <p className='mb-0'>{t('cash-in-success-summery1')}</p>
                    <p className='mb-0'>{t('cash-in-success-summery2')}</p>
                  </div>
                ) : (
                  <div>
                    <h1 className='mb-4 font-bold' style={{ color: 'red' }}>
                      {t('cash-in-failed')}
                    </h1>
                    <p className='mb-0'>{t('cash-in-failed-summery')}</p>
                  </div>
                )}

                <Divider className='my-6' />
                {receiptData?.amount && (
                  <TwoSideText
                    className='mb-2 text-pi-gray-1'
                    left={t('cash-in-amount')}
                    right={formattedAmount({
                      amount: receiptData.amount || 0,
                    })}
                  />
                )}
                {receiptData?.feeAmount && (
                  <TwoSideText
                    className='mb-2 text-pi-gray-1'
                    left={t('administrative-fee')}
                    right={formattedAmount({
                      amount: receiptData.feeAmount || 0,
                    })}
                  />
                )}
                {receiptData?.trxType && (
                  <TwoSideText
                    className='mb-2 text-pi-gray-1'
                    left={t('cash-in-via')}
                    right={receiptData.trxType}
                  />
                )}
                <TwoSideText
                  className='mb-2 text-pi-gray-1'
                  left={t('date', { ns: 'common' })}
                  right={date.format(DD_MMM_YYYY)}
                />
                <TwoSideText
                  className='mb-2 text-pi-gray-1'
                  left={t('time', { ns: 'common' })}
                  right={date.format(HH_MM_SS_A)}
                />
                {receiptData?.transactionHostRefNo && (
                  <TwoSideText
                    className='mb-2 text-pi-gray-1'
                    left={t('reference-no')}
                    right={receiptData.transactionHostRefNo}
                  />
                )}
                {receiptData?.trxRefNo && (
                  <TwoSideText
                    className='mb-2 text-pi-gray-1'
                    left={t('transaction-no')}
                    right={receiptData.trxRefNo}
                  />
                )}
                {receiptData?.pgRefNo && (
                  <TwoSideText
                    className='mb-2 text-pi-gray-1'
                    left={t('epf-reference-no')}
                    right={receiptData?.pgRefNo}
                  />
                )}
                {receiptData?.amount && (
                  <TwoSideText
                    className='text-xl font-bold text-pi-navy-blue my-2'
                    left={`${t('total', { ns: 'common' })}:`}
                    right={formattedAmount({
                      amount: receiptData?.totalAmount || 0,
                    })}
                  />
                )}
                <Button
                  fullWidth
                  variant='contained'
                  size='large'
                  className='mb-8 mt-8'
                  onClick={() => navigate(RoutePathEnum.DASHBOARD)}
                >
                  {t('back-to-dashboard', { ns: 'common' })}
                </Button>
                <Button
                  fullWidth
                  variant='contained'
                  size='large'
                  className='mb-8'
                  onClick={goExternalEpf}
                >
                  {t('back-to-i-Akaun', { ns: 'common' })}
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Receipt
