import { useQueryPurchasingTransaction } from '@/api/orderApi'
import { useMemo } from 'react'

export const useInitReceiptData = (trxRefNo: string) => {
  const {
    isLoading: isLoadingReceiptQuery,
    data: receiptData,
    isError: isReceiptQueryError,
  } = useQueryPurchasingTransaction({
    trxRefNo: trxRefNo ?? '',
    enabled: !!trxRefNo,
  })

  const isLoading = useMemo(
    () => isLoadingReceiptQuery,
    [isLoadingReceiptQuery]
  )
  const isError = useMemo(() => isReceiptQueryError, [isReceiptQueryError])

  return {
    isLoading,
    isError,
    receiptData,
  }
}
