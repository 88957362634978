import {
  OrderApi,
  OrderInstructionConfirmReqDto,
  WrapperTypeEnum,
  PurchasingOrderReqDto,
  SwitchingOrderReqDto,
  RedemptionOrderReqDto,
  TransactionHistorySearchReqDto,
  OrderInstructionQueryReqDto,
  OrderApiGetPurchasingOrderInstructionDataRequestParameters,
  OrderApiGetPurchasingOrderTransactionRequestParameters,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import {
  ORDER_HISTORY_FILTER,
  PURCHASING_INSTRUCTION_INIT,
  PURCHASING_INSTRUCTION_SUBMIT,
  PURCHASING_INSTRUCTION_CONFIRM,
  REDEMPTION_INSTRUCTION_CONFIRM,
  SWITCHING_ORDER_TRX_DETAIL,
  SWITCHING_ORDER_INSTRUCTION_SUBMIT,
  REDEMPTION_INSTRUCTION_SUBMIT,
  SEARCH_TRANSACTION_HISTORY,
  INFINITE,
  QUERY_REDEMPTION_ORDER_INSSTRUCTION,
  SWITCHING_ORDER_INSTRUCTION,
  SWITCHING_ORDER_INSTRUCTION_CONFIRM,
  CUSTOMER_METADATA_FETCH,
  PURCHASING_GET_ORDER_TRXREFNO,
} from '@/constants/apiKeys'
import { TransactionFilterFormProps } from '@/Routes/pages/TransactionHistory/hooks/useTransactionFilterForm'
import { selectEpfToken } from '@/stores/epfToken.selectors'
import { BaseApiConfig, apiClient } from './api-config'
import {
  apiLangCode,
  //  getSessionStorage
} from '@/utils'
import i18n from '@/i18n'

const orderApiClient = new OrderApi(BaseApiConfig, apiClient)

export interface UseQueryPurchasingInstruction
  extends Pick<
    OrderApiGetPurchasingOrderInstructionDataRequestParameters,
    'fundCodes'
  > {}

export interface UseQueryPurchasingTransaction
  extends Pick<
    OrderApiGetPurchasingOrderTransactionRequestParameters,
    'trxRefNo'
  > {
  enabled?: boolean
}

export const useQueryPurchasingInstruction = ({
  fundCodes,
}: UseQueryPurchasingInstruction) => {
  const xEpfToken = useSelector(selectEpfToken) || undefined
  const queryClient = useQueryClient()
  return useQuery({
    queryFn: async () => {
      const res = await orderApiClient.getPurchasingOrderInstructionData({
        xEpfToken,
        fundCodes,
      })
      return res.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CUSTOMER_METADATA_FETCH] })
    },
    queryKey: [PURCHASING_INSTRUCTION_INIT, xEpfToken, fundCodes],
  })
}

export const useQueryPurchasingTransaction = ({
  trxRefNo,
  enabled = true,
}: UseQueryPurchasingTransaction) => {
  return useQuery({
    queryFn: async () => {
      const res = await orderApiClient.getPurchasingOrderTransaction({
        trxRefNo,
      })
      return res.data
    },
    queryKey: [PURCHASING_GET_ORDER_TRXREFNO, trxRefNo],
    enabled,
  })
}

export const useMutationConfirmPurchasingInstruction = () => {
  const xEpfToken = useSelector(selectEpfToken) || undefined
  const langCode = apiLangCode(i18n.language)
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (data: OrderInstructionConfirmReqDto) => {
      const res = await orderApiClient.confirmPurchasingOrderInstruction({
        xEpfToken,
        orderInstructionConfirmReqDto: {
          ...data,
          langCode,
        },
      })
      return res.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SEARCH_TRANSACTION_HISTORY] })
    },
    mutationKey: [PURCHASING_INSTRUCTION_CONFIRM, xEpfToken, langCode],
  })
}

export interface UseMutationOrderHistoryFilter
  extends Pick<TransactionFilterFormProps, 'assetTypes' | 'transactionTypes'> {
  fromDt: string
  toDt: string
}
export const useMutationOrderHistoryFilter = () => {
  return useMutation({
    mutationFn: async (filterData: UseMutationOrderHistoryFilter) => {
      return await new Promise<UseMutationOrderHistoryFilter>((resolve) => {
        setTimeout(() => resolve(filterData), 1000)
      })
    },
    mutationKey: [ORDER_HISTORY_FILTER],
  })
}

export interface UseMutationPurchasingInstruction
  extends Omit<PurchasingOrderReqDto, 'langCode'> {}
export const useMutationPurchasingInstruction = () => {
  const xEpfToken = useSelector(selectEpfToken) || undefined
  const langCode = apiLangCode(i18n.language)
  return useMutation({
    mutationFn: async (data: UseMutationPurchasingInstruction) => {
      const _isEpfWrapper =
        !!data.wrapperType &&
        [
          WrapperTypeEnum.PersonalPortfolioUTCashEPF,
          WrapperTypeEnum.EPFUTEPFeMIS,
        ].includes(data.wrapperType)
      if ((!!xEpfToken && !_isEpfWrapper) || (!xEpfToken && !!_isEpfWrapper)) {
        throw new Error('unexpected epf status')
      }
      const res = await orderApiClient.submitPurchasingOrderInstruction({
        xEpfToken,
        purchasingOrderReqDto: {
          langCode,
          ...data,
        },
      })
      return res.data
    },
    mutationKey: [PURCHASING_INSTRUCTION_SUBMIT, langCode, xEpfToken],
  })
}

export const useMutationConfirmRedemptionOrderInstruction = () => {
  const langCode = apiLangCode(i18n.language)
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (data: OrderInstructionConfirmReqDto) => {
      const res = await orderApiClient.confirmRedemptionOrderInstruction({
        orderInstructionConfirmReqDto: {
          ...data,
          langCode,
        },
      })
      return res.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SEARCH_TRANSACTION_HISTORY] })
    },
    mutationKey: [REDEMPTION_INSTRUCTION_CONFIRM, langCode],
  })
}

export interface SwitchingOrderTrxDetail {
  date: string
  time: string
  referenceNo: string
  transactionId: string
  transactionNo: string
  total: number
}
export const useQuerySwitchingOrderTrxDetail = ({
  trxRefNo,
}: {
  trxRefNo: string
}) => {
  return useQuery({
    queryFn: async () => {
      return {} as SwitchingOrderTrxDetail
      /*const res = await orderApiClient.getSwitchingOrderByTrxRefNo({
        trxRefNo,
      })
      return res.data*/
    },
    queryKey: [SWITCHING_ORDER_TRX_DETAIL, trxRefNo],
  })
}
export const useMutationSubmitSwitchingOrderInstruction = () => {
  const langCode = apiLangCode(i18n.language)
  return useMutation({
    mutationFn: async (data: SwitchingOrderReqDto) => {
      const res = await orderApiClient.submitSwitchingOrderInstruction({
        switchingOrderReqDto: { ...data, langCode },
      })
      return res.data
    },
    mutationKey: [SWITCHING_ORDER_INSTRUCTION_SUBMIT, langCode],
  })
}

export const useMutationSubmitRedemptionOrderInstruction = () => {
  const langCode = apiLangCode(i18n.language)
  const xEpfToken = useSelector(selectEpfToken) || undefined
  return useMutation({
    mutationFn: async (data: RedemptionOrderReqDto) => {
      const res = await orderApiClient.submitRedemptionOrderInstruction({
        redemptionOrderReqDto: {
          ...data,
          langCode,
        },
      })
      return res.data
    },
    mutationKey: [REDEMPTION_INSTRUCTION_SUBMIT, langCode, xEpfToken],
  })
}

export interface UseQuerySearchTransactionHistory
  extends Pick<
    TransactionHistorySearchReqDto,
    'types' | 'assetGroup' | 'dateEnd' | 'dateStart'
  > {}

export const useQuerySearchTransactionHistory = (
  data: UseQuerySearchTransactionHistory
) => {
  return useInfiniteQuery({
    queryFn: async ({ pageParam = 1 }) => {
      const res = await orderApiClient.searchTransactionHistory({
        transactionHistorySearchReqDto: {
          ...data,
          page: pageParam,
          size: 10,
        },
      })
      return res.data
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPage } = lastPage.meta
      return currentPage < totalPage ? currentPage + 1 : undefined
    },
    queryKey: [SEARCH_TRANSACTION_HISTORY, INFINITE, { ...data }],
  })
}

export const useMutationQueryRedemptionOrderInstructionData = () => {
  const xEpfToken = useSelector(selectEpfToken) || undefined
  return useMutation({
    mutationFn: async (data: OrderInstructionQueryReqDto) => {
      const res = await orderApiClient.queryRedemptionOrderInstructionData({
        xEpfToken,
        orderInstructionQueryReqDto: {
          ...data,
        },
      })
      return res.data
    },
    mutationKey: [QUERY_REDEMPTION_ORDER_INSSTRUCTION, xEpfToken],
  })
}

export const useMutationQuerySwitchingOrderInstructionData = () => {
  const xEpfToken = useSelector(selectEpfToken) || undefined
  return useMutation({
    mutationFn: async (data: OrderInstructionQueryReqDto) => {
      const res = await orderApiClient.querySwitchingOrderInstructionData({
        xEpfToken,
        orderInstructionQueryReqDto: {
          ...data,
        },
      })
      return res.data
    },
    mutationKey: [SWITCHING_ORDER_INSTRUCTION],
  })
}

export const useMutationConfirmSwitchingOrderInstruction = () => {
  const langCode = apiLangCode(i18n.language)
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (data: OrderInstructionConfirmReqDto) => {
      const res = await orderApiClient.confirmSwitchingOrderInstruction({
        orderInstructionConfirmReqDto: { ...data, langCode },
      })
      return res.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SEARCH_TRANSACTION_HISTORY] })
    },
    mutationKey: [SWITCHING_ORDER_INSTRUCTION_CONFIRM, langCode],
  })
}
